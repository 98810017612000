
import { defineComponent, onMounted, shallowRef, watch } from 'vue'
import Icon from '@/components/Icon.vue'
import createRaioChart from '@/pages/tradingStyle/components/ratioChart'
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { div, mul, sub } from 'essential/tools/math'
import { IStyle } from '@/pages/tradingStyle/tradingStyle.api'
import Nullable from '@/components/Nullable.vue'

export default defineComponent({
  name: 'TheRatioChart',
  components: {
    Icon,
    TheTradeTips,
    Nullable,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const chartRef = shallowRef()
    const chart = shallowRef()
    const bgColors = ['#326BFE', '#00C3E2']
    const advice = shallowRef('')
    const longAmountRate = shallowRef(0)
    const shortAmountRate = shallowRef(0)
    const longPLRate = shallowRef(0)
    const shortPLRate = shallowRef(0)
    const shortArrowPosition = shallowRef({
      top: -40,
      left: 6,
    })

    const longArrowPosition = shallowRef({
      top: 40,
      left: -6,
    })

    const showTips = shallowRef(false)

    const noDataState = shallowRef(true)

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      const long = data.longAmountRate ? mul(data.longAmountRate, 100) : 0
      const short = data.shortAmountRate ? mul(data.shortAmountRate, 100) : 0
      // const long = 90
      // const short = 10
      advice.value = data.longShortTermAdvice
      longAmountRate.value = mul(data.longAmountRate || 0, 100)
      shortAmountRate.value = mul(data.shortAmountRate || 0, 100)
      longPLRate.value = mul(data.longPLRate || 0, 100)
      shortPLRate.value = mul(data.shortPLRate || 0, 100)
      if (chartRef.value && chart.value) {
        chart.value.resetCtx()
        chart.value.setData({
          long,
          short,
        })
      }

      // 没有数据时（long short 都为0时）显示空状态
      noDataState.value = (long + short === 0)

      setPosition(long)
    })

    const setPosition = (long: number) => {
      const longAngle = div(long, 100) * 360

      // 根据夹角计算描述文字箭头位置
      if (div(longAngle, 2) < 90) {
        const sin = Math.sin(Math.PI * div(90 - div(longAngle, 2), 180))
        shortArrowPosition.value.top = mul(sin, 45)
        longArrowPosition.value.top = -mul(sin, 45)
        shortArrowPosition.value.left = Math.sqrt(sub(mul(45, 45), Math.pow(mul(sin, 45), 2))) + 3
        longArrowPosition.value.left = -shortArrowPosition.value.left - 3
      } else if (div(longAngle, 2) === 90) {
        shortArrowPosition.value.top = 0
        longArrowPosition.value.top = 0
        shortArrowPosition.value.left = 30
        longArrowPosition.value.left = -30
      } else if (div(longAngle, 2) < 180) {
        const sin = Math.sin(Math.PI * div(180 - div(longAngle, 2), 180))
        shortArrowPosition.value.top = -Math.sqrt(sub(mul(45, 45), Math.pow(mul(sin, 45), 2)))
        longArrowPosition.value.top = -shortArrowPosition.value.top
        shortArrowPosition.value.left = mul(sin, 45) + 3
        longArrowPosition.value.left = -mul(sin, 45) - 3
      }
    }

    onMounted(() => {
      chart.value = createRaioChart({
        container: chartRef.value,
      })
    })
    return {
      chartRef,
      bgColors,
      longArrowPosition,
      shortArrowPosition,
      advice,
      longAmountRate,
      shortAmountRate,
      longPLRate,
      shortPLRate,
      showTips,
      noDataState,
    }
  },
})
