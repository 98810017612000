
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { defineComponent, shallowRef, watch } from 'vue'
import { IStyle } from '@/pages/tradingStyle/tradingStyle.api'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'TheHeavyLoss',
  components: {
    TheTradeTips,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const advice = shallowRef('')
    const heavyLoss = shallowRef(0)

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      advice.value = data.heavyLossAdvice
      heavyLoss.value = (data.heavyLoss ? mul(data.heavyLoss, 100) : 0)
    })
    return {
      advice,
      heavyLoss,
    }
  },
})
