
import { defineComponent, shallowRef } from 'vue'
import { formatDateTime } from '@/common/format'

export default defineComponent({
  name: 'TheStatementDate',
  emits: ['change'],
  setup (props, ctx) {
    const preAble = shallowRef(false)
    const nextAble = shallowRef(false)
    const dateList = [] as string[]
    const curDate = shallowRef('')
    const section = 6

    const initData = () => {
      const thisYear = new Date().getFullYear()
      const thisMonth = new Date().getMonth()

      for (let i = 0; i < section; i++) {
        dateList[i] = formatDateTime('yyyy-MM', new Date(thisYear, (thisMonth - i)).valueOf())
      }

      dateList.reverse()
      curDate.value = dateList[section - 1]
      nextAble.value = false
      preAble.value = true
    }
    initData()

    const change = (shift: number) => {
      const cur = dateList.indexOf(curDate.value)
      if (cur + shift > -1 && cur + shift < section) {
        curDate.value = dateList[cur + shift]
        preAble.value = true
        nextAble.value = true
        if (cur + shift === 0) {
          preAble.value = false
        }
        if (cur + shift === section - 1) {
          nextAble.value = false
        }
        ctx.emit('change', dateList.indexOf(curDate.value) + 1 - section)
      }
    }

    return {
      preAble,
      nextAble,
      curDate,
      change,
    }
  },
})

