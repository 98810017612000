<template>
  <div v-if="leverage">
    <t path="tradingstyle_20" class="f-bold title" as="p">Leverage</t>
    <div class="box br-lg">
      <div class="leverage df-middle df-center f-xxl f-bold">
        <t :path="leverage"></t>
      </div>
      <TheTradeTips v-if="advice">
        {{advice}}
      </TheTradeTips>
    </div>
  </div>
</template>

<script lang='ts'>
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { defineComponent, shallowRef, watch } from 'vue'
import { IStyle } from '@/pages/tradingStyle/tradingStyle.api'

export default defineComponent({
  name: 'TheLeverageItem',
  components: {
    TheTradeTips,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const leverageI18n = ['', 'tradingstyle_23', 'tradingstyle_21', 'tradingstyle_22']
    const leverage = shallowRef('')
    const advice = shallowRef('')

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      leverage.value = leverageI18n[data.leverage || 0]
      advice.value = data.leverageAdvice
    })

    return {
      leverage,
      advice,
    }
  },
})
</script>

<style scoped lang='scss'>
.leverage{
  height: 56px;
  color: var(--color-success);
}
.title{
  height: 24px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 8px;
}
</style>
