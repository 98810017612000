<template>
  <t path="tradingstyle_18" class="f-bold title" as="p">Heavy Loss</t>
  <div class="box br-lg">
    <div class="heavy df-col df-middle df-center">
      <t as="p" path="tradingstyle_19" custom="number" class="c-title" style="padding: 0 60px;">
        <template #number>
          <span class="f-xxl f-bold" :class="{'c-success': heavyLoss<=20, 'c-danger': heavyLoss>20}">{{heavyLoss}}%</span>
        </template>
      </t>
    </div>
    <TheTradeTips v-if="advice">
      {{advice}}
    </TheTradeTips>
  </div>
</template>

<script lang='ts'>
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { defineComponent, shallowRef, watch } from 'vue'
import { IStyle } from '@/pages/tradingStyle/tradingStyle.api'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'TheHeavyLoss',
  components: {
    TheTradeTips,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const advice = shallowRef('')
    const heavyLoss = shallowRef(0)

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      advice.value = data.heavyLossAdvice
      heavyLoss.value = (data.heavyLoss ? mul(data.heavyLoss, 100) : 0)
    })
    return {
      advice,
      heavyLoss,
    }
  },
})
</script>

<style scoped lang='scss'>
.heavy{
  height: 80px;
}
.title{
  height: 24px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 8px;
}
</style>
