import requestResult from '@/common/request/request'

export enum Dtypes {
  COMMODITY = 1, // 商品
  FOREIGN_EXCHANGE, // 外汇
  CRYPTOCURRENCY, // 加密货币
  INDEX, // 指数
  STOCK, // 股票
}

export type Distribution = {
  Forex: number;
  Commodities: number;
  Cryptos: number;
  Stock: number;
  Indices: number;
}

export type IStyle = {
  longAmountRate: number;
  shortAmountRate: number;
  longPLRate: number;
  shortPLRate: number;
  longShortTermAdvice: string;
  investmentDistribution: Distribution;
  investmentDistributionAdvice: string;
  stopLossUsage: number;
  takeProfitUsage: number;
  stopOrTakeUsageAdvice: string;
  heavyLoss: number;
  heavyLossAdvice: string;
  leverage: number;
  leverageAdvice: string;
  updateTime: number;
}

export const getStyleInfo = requestResult<IStyle>('/api/user/earnings/style')
