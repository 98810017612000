<template>
  <div class="tip p-8 d-f">
    <Icon name="suggestion" class="mr-8" sm style="color: var(--color-asst);"  />
    <slot />
  </div>
</template>

<script lang='ts'>
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheTradeTips',
  components: {
    Icon,
  },
  setup () {
    return {
    }
  },
})
</script>

<style scoped lang='scss'>
.tip{
  background: var(--color-background);
}
</style>
