
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheTradeTips',
  components: {
    Icon,
  },
  setup () {
    return {
    }
  },
})
