<template>
  <t path="tradingstyle_9" class="f-bold title" as="p">Investment Distribution</t>
  <div class="box br-lg">
    <div class="item-wrap">
      <div class="item df-col df-middle df-center">
        <p class="f-lg f-bold" :class="{'c-success': maxKey==='Forex'}">
          {{mul(investmentDistribution.Forex || 0, 100)}}%
        </p>
        <t path="tradingstyle_10" class="f-md c-title">Forex</t>
      </div>
      <div class="item df-col df-middle df-center">
        <p class="f-lg f-bold" :class="{'c-success': maxKey==='Commodities'}">
          {{mul(investmentDistribution.Commodities || 0, 100)}}%
        </p>
        <t path="tradingstyle_11" class="f-md c-title">Commodities</t>
      </div>
      <div class="item df-col df-middle df-center">
        <p class="f-lg f-bold" :class="{'c-success': maxKey==='Cryptos'}">
          {{mul(investmentDistribution.Cryptos || 0, 100)}}%
        </p>
        <t path="tradingstyle_12" class="f-md c-title">Cryptos</t>
      </div>
      <div class="item df-col df-middle df-center">
        <p class="f-lg f-bold" :class="{'c-success': maxKey==='Stock'}">
          {{mul(investmentDistribution.Stock || 0, 100)}}%
        </p>
        <t path="tradingstyle_13" class="f-md c-title">Stock</t>
      </div>
      <div class="item df-col df-middle df-center">
        <p class="f-lg f-bold" :class="{'c-success': maxKey==='Indices'}">
          {{mul(investmentDistribution.Indices || 0, 100)}}%
        </p>
        <t path="tradingstyle_14" class="f-md c-title">Indices</t>
      </div>
    </div>
    <TheTradeTips v-if="advice">
      {{advice}}
    </TheTradeTips>
  </div>
</template>

<script lang='ts'>
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { defineComponent, shallowRef, watch } from 'vue'
import { IStyle, Distribution } from '@/pages/tradingStyle/tradingStyle.api'
import { mul } from 'essential/tools/math'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheDistribution',
  components: {
    TheTradeTips,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const advice = shallowRef('')
    const investmentDistribution = shallowRef<Distribution>({
      Forex: 0,
      Commodities: 0,
      Cryptos: 0,
      Stock: 0,
      Indices: 0,
    })

    const maxKey = shallowRef('')

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      advice.value = data.investmentDistributionAdvice
      investmentDistribution.value = data.investmentDistribution
      let temp: {key: string, val: number}[] = []
      const objToArr = (x: number | null, key: string) => {
        temp.push({
          key,
          val: x || 0,
        })
      }
      R.mapObjIndexed(objToArr, data.investmentDistribution)
      const sortByKey = R.descend(R.prop('val'))
      temp = R.sort(sortByKey, temp)

      maxKey.value = temp.length > 0 ? temp[0].key : ''
    })
    return {
      advice,
      investmentDistribution,
      mul,
      maxKey,
    }
  },
})
</script>

<style scoped lang='scss'>
.item-wrap{
  display: flex;
  flex-wrap: wrap;
  .item{
    max-width: 33.33%;
    min-width: 33.33%;
    height: 64px;
  }
}
.title{
  height: 24px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 8px;
}
</style>
