<template>
  <div>
    <div class="mb-8 p-r">
      <t path="tradingstyle_4" class="f-bold mr-4">Long / Short Term</t>
      <Icon name="help" style="color: var(--color-primary);"  @click.stop="() => showTips=true"/>
      <div class="tip-wrap" v-if="showTips">
        <div class="tips br-lg p-r p-16 f-sm">
          <t path="tradingstyle_24" style="line-height:20px;" as="div"
            multiline #="{td}" custom>
            <p class="c-white mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
          </t>
          <div class="df-center mt-16">
            <button class="got-btn" @click.stop="() => showTips=false">
              <t path="tradingstyle_25">I Know</t>
            </button>
          </div>
          <div class="arrow"></div>
        </div>
      </div>
    </div>
    <div class="br-lg box" v-show="!noDataState">
      <div class="p-r canvas-main">
        <div class="canvas" ref="chartRef"></div>

        <div class="short" v-if="shortAmountRate>0" :style="`margin-top: ${shortArrowPosition.top}px;margin-left: ${shortArrowPosition.left}px`">
          <div class="s-arow p-r">
            <div class="chart-info p-8 br-lg f-sm"
              style="line-height: 20px;">
              <t as="p" path="tradingstyle_5" custom="number, plnumber">
                <template #number>
                  <span class="f-bold" :style="`color: ${bgColors[1]}`">{{shortAmountRate}}%</span>
                  <br />
                </template>
                <template #plnumber>
                  <span class="f-bold" :class="{'c-success':shortPLRate>=0,'c-danger':shortPLRate<0}">{{shortPLRate}}%</span>
                </template>
              </t>
            </div>
          </div>
        </div>
        <div class="long" v-if="longAmountRate>0" :style="`margin-top:${longArrowPosition.top}px; margin-left: ${longArrowPosition.left}px`">
          <div class="l-arow p-r">
            <div class="chart-info p-8 br-lg f-sm"
              style="line-height: 20px;">
              <t as="p" path="tradingstyle_6" custom="number, plnumber">
                <template #number>
                  <span class="f-bold" :style="`color: ${bgColors[0]}`">{{longAmountRate}}%</span>
                  <br />
                </template>
                <template #plnumber>
                  <span class="f-bold" :class="{'c-success':longPLRate>=0,'c-danger':longPLRate<0}">{{longPLRate}}%</span>
                </template>
              </t>
            </div>
          </div>
        </div>

      </div>
      <div class="t-color df-between">
        <div class="df-middle">
          <div class="tc-item" :style="`background: ${bgColors[0]}`"></div>
          <t path="tradingstyle_7" class="f-bold ml-4" :style="`color: ${bgColors[0]}`">Long-term</t>
        </div>
        <div class="df-middle">
          <div class="tc-item" :style="`background: ${bgColors[1]}`"></div>
          <t path="tradingstyle_8" class="f-bold ml-4" :style="`color: ${bgColors[1]}`">Short-term</t>
        </div>
      </div>
      <TheTradeTips v-if="advice">{{ advice }}</TheTradeTips>
    </div>

    <div v-if="noDataState" class="box br-lg df-middle" style="height: 220px;">
      <Nullable />
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, shallowRef, watch } from 'vue'
import Icon from '@/components/Icon.vue'
import createRaioChart from '@/pages/tradingStyle/components/ratioChart'
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { div, mul, sub } from 'essential/tools/math'
import { IStyle } from '@/pages/tradingStyle/tradingStyle.api'
import Nullable from '@/components/Nullable.vue'

export default defineComponent({
  name: 'TheRatioChart',
  components: {
    Icon,
    TheTradeTips,
    Nullable,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const chartRef = shallowRef()
    const chart = shallowRef()
    const bgColors = ['#326BFE', '#00C3E2']
    const advice = shallowRef('')
    const longAmountRate = shallowRef(0)
    const shortAmountRate = shallowRef(0)
    const longPLRate = shallowRef(0)
    const shortPLRate = shallowRef(0)
    const shortArrowPosition = shallowRef({
      top: -40,
      left: 6,
    })

    const longArrowPosition = shallowRef({
      top: 40,
      left: -6,
    })

    const showTips = shallowRef(false)

    const noDataState = shallowRef(true)

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      const long = data.longAmountRate ? mul(data.longAmountRate, 100) : 0
      const short = data.shortAmountRate ? mul(data.shortAmountRate, 100) : 0
      // const long = 90
      // const short = 10
      advice.value = data.longShortTermAdvice
      longAmountRate.value = mul(data.longAmountRate || 0, 100)
      shortAmountRate.value = mul(data.shortAmountRate || 0, 100)
      longPLRate.value = mul(data.longPLRate || 0, 100)
      shortPLRate.value = mul(data.shortPLRate || 0, 100)
      if (chartRef.value && chart.value) {
        chart.value.resetCtx()
        chart.value.setData({
          long,
          short,
        })
      }

      // 没有数据时（long short 都为0时）显示空状态
      noDataState.value = (long + short === 0)

      setPosition(long)
    })

    const setPosition = (long: number) => {
      const longAngle = div(long, 100) * 360

      // 根据夹角计算描述文字箭头位置
      if (div(longAngle, 2) < 90) {
        const sin = Math.sin(Math.PI * div(90 - div(longAngle, 2), 180))
        shortArrowPosition.value.top = mul(sin, 45)
        longArrowPosition.value.top = -mul(sin, 45)
        shortArrowPosition.value.left = Math.sqrt(sub(mul(45, 45), Math.pow(mul(sin, 45), 2))) + 3
        longArrowPosition.value.left = -shortArrowPosition.value.left - 3
      } else if (div(longAngle, 2) === 90) {
        shortArrowPosition.value.top = 0
        longArrowPosition.value.top = 0
        shortArrowPosition.value.left = 30
        longArrowPosition.value.left = -30
      } else if (div(longAngle, 2) < 180) {
        const sin = Math.sin(Math.PI * div(180 - div(longAngle, 2), 180))
        shortArrowPosition.value.top = -Math.sqrt(sub(mul(45, 45), Math.pow(mul(sin, 45), 2)))
        longArrowPosition.value.top = -shortArrowPosition.value.top
        shortArrowPosition.value.left = mul(sin, 45) + 3
        longArrowPosition.value.left = -mul(sin, 45) - 3
      }
    }

    onMounted(() => {
      chart.value = createRaioChart({
        container: chartRef.value,
      })
    })
    return {
      chartRef,
      bgColors,
      longArrowPosition,
      shortArrowPosition,
      advice,
      longAmountRate,
      shortAmountRate,
      longPLRate,
      shortPLRate,
      showTips,
      noDataState,
    }
  },
})
</script>

<style scoped lang='scss'>
.canvas-main{
  height: 220px;
}
.canvas{
  height: 140px;
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -70px;
  margin-left: -70px;
}
.long{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.l-arow{
  border-style: solid;
  border-color: transparent;
  border-width: 8px 0 8px 12px;
  border-left-color: rgba(236, 239, 244, 0.7);
  .chart-info{
    position: absolute;
    width: calc(50vw - 70px);
    left: 50%;
    top: 50%;
    transform: translate(-100%, -50%);
    margin-left: -12px;
    background-color: rgba(236, 239, 244, 0.7);
  }
}
.short{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.s-arow{
  border-style: solid;
  border-color: transparent;
  border-width: 8px 12px 8px 0;
  border-right-color: rgba(236, 239, 244, 0.7);
  .chart-info{
    position: absolute;
    width: calc(50vw - 70px);
    left: 50%;
    top: 50%;
    transform: translate(12px, -50%);
    background-color: rgba(236, 239, 244, 0.7);
  }
}

.t-color{
  width: 100%;
  padding: 0 52px;
  padding-bottom: 16px;
}
.tc-item{
  width: 12px;
  height: 12px;
  border-radius: 3px;
}

.tip-wrap{
  position: absolute;
  z-index: 1;
  top: 32px;
  .tips{
    background-color: rgba(24, 29, 36, 0.9);
    color: var(--color-white);
    .arrow{
      position: absolute;
      top: -8px;
      left: 50%;
      margin-left: -46px;
      border-style: solid;
      border-color: transparent;
      border-width: 0 6px 8px 6px;
      border-bottom-color: rgba(24, 29, 36, 0.9);
    }
    .got-btn{
      background-color: var(--color-primary);
      height: 28px;
      border-style: none;
      border-radius: 14px;
      padding: 0 16px;
    }
  }
}
</style>
