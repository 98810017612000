<template>
  <PageWithHeader>
    <template #title>
      <t path="tradingstyle_2">TradingStyle</t>
    </template>
    <div class="px-16 pb-24">
      <TheStatementDate @change="handleDateChange" />
      <t as="p" path="tradingstyle_3" :args="{date: endDate}" class="c-tip f-sm mt-8 mb-16">Data ended by {{ endDate }}</t>
      <TheRatioChart :page-data="pageData"/>
      <TheDistribution :page-data="pageData"/>
      <TheUsageRate :page-data="pageData"/>
      <TheHeavyLoss :page-data="pageData"/>
      <TheLeverageItem :page-data="pageData" />
    </div>
  </PageWithHeader>
</template>

<script lang='ts'>
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { defineComponent, shallowRef } from 'vue'
import TheStatementDate from '@/pages/statement/components/TheStatementDate.vue'
import TheRatioChart from '@/pages/tradingStyle/components/TheRatioChart.vue'
import TheLeverageItem from '@/pages/tradingStyle/components/TheLeverageItem.vue'
import TheDistribution from '@/pages/tradingStyle/components/TheDistribution.vue'
import TheUsageRate from '@/pages/tradingStyle/components/TheUsageRate.vue'
import TheHeavyLoss from '@/pages/tradingStyle/components/TheHeavyLoss.vue'

import { getStyleInfo, IStyle } from '@/pages/tradingStyle/tradingStyle.api'

export default defineComponent({
  name: 'TradingStyle',
  components: {
    PageWithHeader,
    TheRatioChart,
    TheStatementDate,
    TheLeverageItem,
    TheDistribution,
    TheUsageRate,
    TheHeavyLoss,
  },
  setup () {
    const pageData = shallowRef<IStyle>()
    const dateIndex = shallowRef(0)
    const endDate = shallowRef('')
    const handleDateChange = (index: number) => {
      dateIndex.value = index
      getPageData()
    }

    const getPageData = () => {
      getStyleInfo({
        pageNumber: dateIndex.value,
      }).then(res => {
        pageData.value = res
        endDate.value = new Date(res.updateTime).toUTCString()
      })
    }

    getPageData()

    return {
      handleDateChange,
      pageData,
      endDate,
    }
  },
})
</script>

<style scoped lang='scss'>

</style>
