import * as R from 'ramda'
import { div } from 'essential/tools/math'

export interface QuoteObject {
  long: number;
  short: number;
}

export interface Angle {
  s: number;
  e: number;
}

export interface ChartOption {
  container: HTMLElement;
}

class Chart {
  private container
  private canvas: HTMLCanvasElement
  private ctx: CanvasRenderingContext2D
  private ratio: number
  private width: number
  private height: number
  private colors: string[]
  constructor (options: ChartOption) {
    // init
    this.container = Chart.getContainer(options.container)
    this.canvas = Chart.createCanvas()
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D //  this.getContext('2d')
    this.ratio = window.devicePixelRatio || 2
    this.width = 0
    this.height = 0
    this.colors = ['#326BFE', '#00C3E2']
    this.resize()
    this.append()
  }

  static getContainer (c: HTMLElement) {
    return R.ifElse(R.is(String), document.querySelector, R.identity)(c)
  }

  static createCanvas () {
    return document.createElement('canvas')
  }

  getContext (id: string) {
    return this.canvas.getContext(id)
  }

  resize () {
    const width = 140
    const height = 140
    this.canvas.width = width
    this.canvas.height = height

    this.height = height
    this.width = width

    const { ratio, canvas, ctx } = this

    canvas.height = height * ratio
    canvas.width = width * ratio
    canvas.style.height = `${height}px`
    canvas.style.width = `${width}px`
    ctx.scale(ratio, ratio)
  }

  append () {
    this.container.appendChild(this.canvas)
  }

  resetCtx () {
    this.ctx.clearRect(0, 0, this.width, this.height)
  }

  draw (dirArr: Angle[]) {
    for (let i = 0; i < dirArr.length; i++) {
      this.ctx.lineWidth = 44
      this.ctx.strokeStyle = this.colors[i]
      this.ctx.beginPath()
      this.ctx.arc(70, 70, 46, dirArr[i].s, dirArr[i].e, true)
      this.ctx.stroke()
    }
  }

  setData (data: QuoteObject) {
    const temp = [data.long, data.short]
    let sPoint = -Math.PI * 2 * 0.25
    const dirArr = []

    if (temp.length === 0) return
    for (let i = 0; i < temp.length; i++) {
      if (isNaN(temp[i])) return
      const s = sPoint
      const e = sPoint - (Math.PI * 2 * div(temp[i], 100))
      sPoint = e
      dirArr.push({
        s,
        e,
      })
    }
    this.draw(dirArr)
  }

  remove () {
    this.container.removeChild(this.canvas)
  }
}

export default (options: ChartOption) => new Chart(options)
