
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { defineComponent, shallowRef, watch } from 'vue'
import { IStyle } from '@/pages/tradingStyle/tradingStyle.api'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'TheUsageRate',
  components: {
    TheTradeTips,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const advice = shallowRef('')
    const stopLossUsage = shallowRef(0)
    const takeProfitUsage = shallowRef(0)

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      advice.value = data.stopOrTakeUsageAdvice
      stopLossUsage.value = mul(data.stopLossUsage || 0, 100)
      takeProfitUsage.value = mul(data.takeProfitUsage || 0, 100)
    })
    return {
      advice,
      stopLossUsage,
      takeProfitUsage,
    }
  },
})
