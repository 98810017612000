
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { defineComponent, shallowRef, watch } from 'vue'
import { IStyle, Distribution } from '@/pages/tradingStyle/tradingStyle.api'
import { mul } from 'essential/tools/math'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheDistribution',
  components: {
    TheTradeTips,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const advice = shallowRef('')
    const investmentDistribution = shallowRef<Distribution>({
      Forex: 0,
      Commodities: 0,
      Cryptos: 0,
      Stock: 0,
      Indices: 0,
    })

    const maxKey = shallowRef('')

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      advice.value = data.investmentDistributionAdvice
      investmentDistribution.value = data.investmentDistribution
      let temp: {key: string, val: number}[] = []
      const objToArr = (x: number | null, key: string) => {
        temp.push({
          key,
          val: x || 0,
        })
      }
      R.mapObjIndexed(objToArr, data.investmentDistribution)
      const sortByKey = R.descend(R.prop('val'))
      temp = R.sort(sortByKey, temp)

      maxKey.value = temp.length > 0 ? temp[0].key : ''
    })
    return {
      advice,
      investmentDistribution,
      mul,
      maxKey,
    }
  },
})
