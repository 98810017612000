
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { defineComponent, shallowRef, watch } from 'vue'
import { IStyle } from '@/pages/tradingStyle/tradingStyle.api'

export default defineComponent({
  name: 'TheLeverageItem',
  components: {
    TheTradeTips,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const leverageI18n = ['', 'tradingstyle_23', 'tradingstyle_21', 'tradingstyle_22']
    const leverage = shallowRef('')
    const advice = shallowRef('')

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      leverage.value = leverageI18n[data.leverage || 0]
      advice.value = data.leverageAdvice
    })

    return {
      leverage,
      advice,
    }
  },
})
