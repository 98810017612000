<template>
  <t path="tradingstyle_15" class="f-bold title" as="p">Stop-Loss / Take profit usage rate</t>
  <div class="box br-lg">
    <div class="item-wrap df-middle">
      <div class="item df-col df-middle df-center">
        <p class="f-xxl f-bold" :class="{'c-success': stopLossUsage>=40, 'c-danger': stopLossUsage<40}">{{stopLossUsage}}%</p>
        <t path="tradingstyle_16" class="f-md c-title">Stop-Loss usage</t>
      </div>
      <div class="item df-col df-middle df-center">
        <p class="f-xxl f-bold" :class="{'c-success': takeProfitUsage>=40, 'c-danger': takeProfitUsage<40}">{{takeProfitUsage}}%</p>
        <t path="tradingstyle_17" class="f-md c-title">Take-Profit usage</t>
      </div>
    </div>
    <TheTradeTips v-if="advice">
      {{advice}}
    </TheTradeTips>
  </div>
</template>

<script lang='ts'>
import TheTradeTips from '@/pages/tradingStyle/components/TheTradeTips.vue'
import { defineComponent, shallowRef, watch } from 'vue'
import { IStyle } from '@/pages/tradingStyle/tradingStyle.api'
import { mul } from 'essential/tools/math'

export default defineComponent({
  name: 'TheUsageRate',
  components: {
    TheTradeTips,
  },
  props: {
    pageData: Object,
  },
  setup (props) {
    const advice = shallowRef('')
    const stopLossUsage = shallowRef(0)
    const takeProfitUsage = shallowRef(0)

    watch(() => props.pageData, () => {
      const data = props.pageData as IStyle
      advice.value = data.stopOrTakeUsageAdvice
      stopLossUsage.value = mul(data.stopLossUsage || 0, 100)
      takeProfitUsage.value = mul(data.takeProfitUsage || 0, 100)
    })
    return {
      advice,
      stopLossUsage,
      takeProfitUsage,
    }
  },
})
</script>

<style scoped lang='scss'>
.item-wrap{
  height: 80px;
  .item{
    max-width: 50%;
    min-width: 50%;
  }
}
.title{
  height: 24px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 8px;
}
</style>
